import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "header",
  class: "app-header navbar navbar-expand-lg p-0"
}
const _hoisted_2 = { class: "container-xxl px-3 px-lg-5" }
const _hoisted_3 = { class: "brand" }
const _hoisted_4 = {
  class: "collapse navbar-collapse",
  id: "navbarContent"
}
const _hoisted_5 = { class: "navbar-nav ms-auto mb-2 mb-lg-0 fw-500" }
const _hoisted_6 = { class: "nav-item me-2" }
const _hoisted_7 = { class: "nav-item me-2" }
const _hoisted_8 = { class: "nav-item me-2" }
const _hoisted_9 = { class: "ms-3" }
const _hoisted_10 = {
  href: "https://cp.key2host.com",
  class: "btn btn-theme fw-bold rounded-pill px-3 py-2"
}

import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {

function isActiveRoute(path: string) {
	const currentRoute = useRouter().currentRoute.value.path;
	var match = false;
	if (currentRoute == path || currentRoute + '/' == path) {
		match = true;
	}
	return match;
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("button", {
        class: "navbar-toggler border-0 p-0 me-3 fs-24px shadow-none",
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#navbarContent"
      }, [
        _createElementVNode("span", { class: "h-2px w-25px bg-gray-500 d-block mb-1" }),
        _createElementVNode("span", { class: "h-2px w-25px bg-gray-500 d-block" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          class: "container brand-bg",
          to: "/"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: "/assets/img/brand/logo-wide.svg",
              alt: "Key2Host Logo"
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_RouterLink, {
              to: "/",
              class: _normalizeClass(['nav-link', { active: isActiveRoute('/') }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("APP.HEADER.HOME")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_RouterLink, {
              to: "/shop",
              class: _normalizeClass(['nav-link', { active: isActiveRoute('/shop') }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("APP.HEADER.SHOP")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_RouterLink, {
              to: "/contact",
              class: _normalizeClass(['nav-link', { active: isActiveRoute('/contact') }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("APP.HEADER.CONTACT")), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, [
          _createTextVNode(_toDisplayString(_ctx.$t("APP.HEADER.LOGIN")) + " ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-arrow-right ms-2 opacity-5" }, null, -1))
        ])
      ])
    ])
  ]))
}
}

})