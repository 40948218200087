<script setup lang="ts">
import { useRouter } from 'vue-router'

function isActiveRoute(path: string) {
	const currentRoute = useRouter().currentRoute.value.path;
	var match = false;
	if (currentRoute == path || currentRoute + '/' == path) {
		match = true;
	}
	return match;
}
</script>
<style>
.app-header {
	& .brand {
		width: 175px !important;
	}
}
</style>
<template>
	<div id="header" class="app-header navbar navbar-expand-lg p-0">
		<div class="container-xxl px-3 px-lg-5">
			<button class="navbar-toggler border-0 p-0 me-3 fs-24px shadow-none" type="button" data-bs-toggle="collapse"
				data-bs-target="#navbarContent">
				<span class="h-2px w-25px bg-gray-500 d-block mb-1"></span>
				<span class="h-2px w-25px bg-gray-500 d-block"></span>
			</button>
			<div class="brand">
				<RouterLink class="container brand-bg" to="/">
					<img src="/assets/img/brand/logo-wide.svg" alt="Key2Host Logo">
				</RouterLink>
			</div>
			<div class="collapse navbar-collapse" id="navbarContent">
				<div class="navbar-nav ms-auto mb-2 mb-lg-0 fw-500">
					<div class="nav-item me-2">
						<RouterLink to="/" :class="['nav-link', { active: isActiveRoute('/') }]">{{
							$t("APP.HEADER.HOME") }}</RouterLink>
					</div>
					<div class="nav-item me-2">
						<RouterLink to="/shop" :class="['nav-link', { active: isActiveRoute('/shop') }]">{{
							$t("APP.HEADER.SHOP") }}</RouterLink>
					</div>
					<div class="nav-item me-2">
						<RouterLink to="/contact" :class="['nav-link', { active: isActiveRoute('/contact') }]">{{
							$t("APP.HEADER.CONTACT") }}</RouterLink>
					</div>
				</div>
			</div>
			<div class="ms-3">
				<a href="https://cp.key2host.com" class="btn btn-theme fw-bold rounded-pill px-3 py-2">{{
					$t("APP.HEADER.LOGIN") }} <i class="fa fa-arrow-right ms-2 opacity-5"></i></a>
			</div>
		</div>
	</div>
</template>