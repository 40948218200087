<script>
export default {
	data() {
		return {
			year: (new Date()).getFullYear()
		}
	},
	methods: {
		getLanguage() {
			var l;
			switch (this.$i18n.locale) {
				case 'de':
					l = "Deutschland (Deutsch)";
					break;
				case 'en':
					l = "United States (English)";
					break;
				case 'es':
					l = "España (Español)";
					break;
				case 'fr':
					l = "France (Français)";
					break;
				case 'gr':
					l = "Ελλάδα (ελληνικά)";
					break;
				case 'tr':
					l = "Türkiye (Türkçe)";
					break;
				default:
					l = "-";
					break;
			}
			return l;
		}
	}
}
</script>
<style>
.app-footer {
	& .brand-bg {
		background: rgb(102,159,255);
      background: -moz-linear-gradient(135deg, rgba(102,159,255,1) 0%, rgba(28,40,89,1) 100%);
      background: -webkit-linear-gradient(135deg, rgba(102,159,255,1) 0%, rgba(28,40,89,1) 100%);
      background: linear-gradient(135deg, rgba(102,159,255,1) 0%, rgba(28,40,89,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#669fff",endColorstr="#1c2859",GradientType=1);
		opacity: 95%;
		height: 100px;
		border-radius: 5px;
		align-items: center;
		justify-content: center;
	}

	& img {
		width: 85%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
<template>
	<!-- BEGIN #footer -->
	<div id="footer" class="app-footer py-5 bg-gray-800 text-body text-opacity-75" data-bs-theme="dark">
		<div class="container-xxl px-3 px-lg-5">
			<div class="row gx-lg-5 gx-3 gy-lg-4 gy-3">
				<div class="col-lg-3 col-md-6 mb-lg-0 mb-3">
					<div class="mb-3 brand-bg">
						<img src="/assets/img/brand/logo-wide.svg" alt="Key2Host Logo">
					</div>
					<p class="mb-4">{{ $t("APP.FOOTER.BIO") }}</p>
					<h5>{{ $t("APP.FOOTER.FOLLOWUS") }}</h5>
					<div class="d-flex">
						<a href="https://facebook.com/key2hostde" target="_blank" class="me-2 text-body text-opacity-50"><i
								class="fab fa-lg fa-facebook fa-fw"></i></a>
						<a href="https://instagram.com/key2host" target="_blank" class="me-2 text-body text-opacity-50"><i
								class="fab fa-lg fa-instagram fa-fw"></i></a>
						<a href="https://x.com/key2hostde" target="_blank" class="me-2 text-body text-opacity-50"><i
								class="fab fa-lg fa-twitter fa-fw"></i></a>
						<a href="https://discord.gg/R23NYcV4P4" target="_blank" class="me-2 text-body text-opacity-50"><i
								class="fab fa-lg fa-discord fa-fw"></i></a>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 my-auto">
					<h3 class="mb-2">{{ $t("APP.FOOTER.PRODUTCS.TITLE") }}</h3>
					<ul class="list-unstyled">
						<li class="mb-2"><RouterLink to="/shop/product/webspaces" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.PRODUTCS.MENU.1") }}</RouterLink></li>
						<li class="mb-2"><RouterLink to="/shop/product/domains" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.PRODUTCS.MENU.2") }}</RouterLink></li>
						<li class="mb-2"><RouterLink to="/shop/product/vps" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.PRODUTCS.MENU.3") }}</RouterLink></li>
						<li class="mb-2"><RouterLink to="/shop/product/kvm" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.PRODUTCS.MENU.4") }}</RouterLink></li>
						<li class="mb-2"><RouterLink to="/shop/product/dedi" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.PRODUTCS.MENU.5") }}</RouterLink></li>
						<li class="mb-2"><RouterLink to="/shop/product/licenses" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.PRODUTCS.MENU.6") }}</RouterLink></li>
					</ul>
				</div>
				<div class="col-lg-3 col-md-6 my-auto">
					<h3 class="mb-2">{{ $t("APP.FOOTER.HELPCENTER.TITLE") }}</h3>
					<ul class="list-unstyled">
						<li class="mb-2"><RouterLink to="/contact" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.HELPCENTER.MENU.1") }}</RouterLink></li>
						<li class="mb-2"><a href="https://help.key2host.com" target="_blank" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.HELPCENTER.MENU.2") }}</a></li>
						<li class="mb-2"><a href="https://status.key2host.com" target="_blank" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.HELPCENTER.MENU.3") }}</a>
						</li>
						<li class="mb-2"><a href="https://docs.key2host.com" target="_blank" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.HELPCENTER.MENU.4") }}</a></li>
						<li class="mb-2"><a href="https://developer.key2host.com" target="_blank" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.HELPCENTER.MENU.5") }}</a></li>
						<li class="mb-2"><a href="/404" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.HELPCENTER.MENU.6") }}</a></li>
					</ul>
				</div>
				<div class="col-lg-3 col-md-6 my-auto">
					<h3 class="mb-2">{{ $t("APP.FOOTER.ABOUT.TITLE") }}</h3>
					<ul class="list-unstyled">
						<li class="mb-2"><a href="#" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.ABOUT.MENU.1") }}</a></li>
						<li class="mb-2"><a href="#" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.ABOUT.MENU.2") }}</a></li>
						<li class="mb-2"><a href="#" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.ABOUT.MENU.3") }}</a></li>
						<li class="mb-2"><a href="#" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.ABOUT.MENU.4") }}</a></li>
						<li class="mb-2"><a href="#" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.ABOUT.MENU.5") }}</a></li>
						<li class="mb-2"><a href="#" class="text-decoration-none text-body text-opacity-75">{{
							$t("APP.FOOTER.ABOUT.MENU.6") }}</a></li>
					</ul>
				</div>
			</div>
			<hr class="text-body text-opacity-50">
			<div class="row">
				<div class="col-sm-4 text-sm-start d-none d-lg-block">
					<RouterLink to="/legal-notice" class="text-decoration-none text-body text-opacity-50 me-4">{{
						$t("APP.FOOTER.LEGALMENU.LEGALNOTICE") }}</RouterLink>
					<RouterLink to="/privacy-policy" class="text-decoration-none text-body text-opacity-50 me-4">{{
						$t("APP.FOOTER.LEGALMENU.PRIVACYPOLICY") }}</RouterLink>
					<RouterLink to="/tos" class="text-decoration-none text-body text-opacity-50 me-4">{{
						$t("APP.FOOTER.LEGALMENU.TERMSOFSERVICE") }}
					</RouterLink>
				</div>
				<div class="col-sm-4 mb-2 text-sm-start d-lg-none">
					<div class="dropdown me-4 d-inline">
						<a href="#" class="text-decoration-none dropdown-toggle text-body text-opacity-50"
							data-bs-toggle="dropdown"><i class="fa-solid fa-scale-balanced"></i> {{
								$t("APP.FOOTER.LEGALMENU.TITLE") }}</a>
						<ul class="dropdown-menu">
							<li>
								<RouterLink to="/legal-notice" class="dropdown-item">{{
									$t("APP.FOOTER.LEGALMENU.LEGALNOTICE") }}</RouterLink>
							</li>
							<li>
								<RouterLink to="/privacy-policy" class="dropdown-item">{{
									$t("APP.FOOTER.LEGALMENU.PRIVACYPOLICY") }}</RouterLink>
							</li>
							<li>
								<RouterLink to="/tos" class="dropdown-item">{{
									$t("APP.FOOTER.LEGALMENU.TERMSOFSERVICE") }}</RouterLink>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-sm-4 mb-2 text-sm-center">
					<div class="footer-copyright-text">Copyright &copy; {{ year }} <RouterLink to="/"
							class="text-decoration-none">Key2Host</RouterLink> - {{ $t("APP.LIC") }}.</div>
				</div>
				<div class="col-sm-4 text-sm-end">
					<RouterLink to="/choose-lang" class="text-decoration-none text-body text-opacity-50">
						<i class="fa-solid fa-globe"></i> {{ getLanguage() }}
					</RouterLink>
				</div>
			</div>
		</div>
	</div>
	<!-- END #footer -->
</template>