<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import { useAppOptionStore } from '@/apps/landing/stores/app-option';
import { useAppVariableStore, generateVariables } from '@/shared/stores/app-variable';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import AppHeader from '@/apps/landing/components/Header.vue';
import AppFooter from '@/apps/landing/components/Footer.vue';
import router from './router';

const appVariable = useAppVariableStore();
const appOption = useAppOptionStore();
const progresses = [] as ProgressFinisher[];
var isLoading = ref(false);
var loadingDots = ref(".");

onMounted(() => {
	let dotCount = 1;
	setInterval(() => {
		if (dotCount > 3) dotCount = 1;
		loadingDots.value = ".".repeat(dotCount);
		dotCount++;
	}, 750);
});

router.beforeEach(async (to, from) => {
	isLoading.value = true;
	progresses.push(useProgress().start());
})
router.afterEach(async (to, from) => {
	isLoading.value = false;
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	progresses.pop()?.finish();
})

function changeColorSheme(newColorScheme) {
	appOption.appMode = newColorScheme;
	document.documentElement.setAttribute('data-bs-theme', newColorScheme);
	var newVariables = generateVariables();
	appVariable.font = newVariables.font;
	appVariable.color = newVariables.color;
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
	const newColorScheme = event.matches ? "dark" : "light";
	changeColorSheme(newColorScheme);
});

if(window.matchMedia('(prefers-color-scheme: dark)').matches) {
	changeColorSheme("dark");
}

document.querySelector('body').classList.add('app-init');
</script>

<template>
	<div class="app" v-bind:class="{
		'app-header-menu-search-toggled': appOption.appHeaderSearchToggled,
		'app-content-full-height': appOption.appContentFullHeight,
		'app-content-full-width': true,
		'app-without-header': appOption.appHeaderHide,
		'app-boxed-layout': appOption.appBoxedLayout,
		'app-footer-fixed': appOption.appFooterFixed,
	}">
		<vue3-progress-bar />
		<div v-if="isLoading" class="d-flex justify-content-center align-items-center">
			<div class="position-fixed top-0 start-0 w-100 h-100 bg-black opacity-90 d-flex flex-column align-items-center justify-content-center"
				style="z-index: 9999;">
				<div class="spinner-border mb-2 text-white" style="width: 3rem; height: 3rem;" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span class="text-white">{{ $t("APP.OTHER.WAIT") + loadingDots }}</span>
			</div>
		</div>
		<app-header v-if="!appOption.appHeaderHide" />
		<div class="app-content" v-bind:class="appOption.appContentClass">
			<router-view></router-view>
		</div>
		<app-footer v-if="appOption.appFooter" />
	</div>
</template>
